import HeaderImageUrl from "assets/summerHeader.png";
import { CommonContentBlock, HeaderImage } from "components/ContentBlock";
import { Paragraph, Header3, TextLinkA } from "components/Typography";
import React from "react";

export const SummerDetails: React.FC = () => {
    return (
        <React.Fragment>
            <HeaderImage
                imageUrl={HeaderImageUrl}
                gradient="linear-gradient(90deg, rgba(0, 0, 0, 0.5) 14.31%, rgba(0, 0, 0, 0) 100%)"
                headerText="CURIS Summer Internship Program"
            />
            <CommonContentBlock
                header="Program Overview"
                description={
                    <Paragraph>
                        The CURIS summer program is for Stanford undergraduates only. Students work with a faculty member in the CS department, joining their group for an entire summer working on a project of mutual interest. Students receive a one-time stipend or salary for the summer. The roadmap for prospective CURIS participants is as follows:
                        <ul>
                            <li>
                                <b>Winter Quarter:</b> Students apply to and get matched to
                                projects.
                            </li>
                            <li>
                                <b>Spring Quarter:</b> Students prepare for their internships with their mentors. This may involve taking a class, reading papers, participating in group meetings, etc.
                            </li>
                            <li>
                                <b>Summer Quarter:</b> Students perform research for 10 weeks (40 hours / week). Each student receives $8,000 for this period. Students who qualify for the need-based supplement will receive an additional $1,500  &nbsp;
                                <TextLinkA
                                    href="https://undergrad.stanford.edu/opportunities/research/information-faculty-staff/faculty-department-grant-administration"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >as determined by VPUE's Stipend Guidelines.
                                </TextLinkA> &nbsp;
                                Student are usually eligible to purchase on-campus room and board on campus. More information on summer housing can be found{" "}
                                <TextLinkA
                                    href="https://rde.stanford.edu/summer-housing-assignments"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </TextLinkA>.
                            </li>
                            <li>
                                <b>End of summer:</b> Students present their work at a poster
                                session with other CURIS participants.
                            </li>
                        </ul>
                    </Paragraph>
                }
            />
            <CommonContentBlock
                dark
                header="Program Requirements"
                description={
                    <Paragraph>
                        The summer CURIS program requires students to:
                        <ul>
                            <li>
                                Do research on campus for a full 10 weeks over summer. We strongly
                                recommend that these ten weeks be the official ten week timeline
                                below. However, if you need to be away for part of the official
                                CURIS session, your faculty mentor and the CURIS staff can approve
                                up to four weeks of vacation on the condition that you you begin
                                your internship early or end your internship late to make up any
                                missed days before the start of fall quarter. Please email Yesenia Gallegos,{" "}
                                <TextLinkA href="mailto:ygallego@stanford.edu">
                                ygallego@stanford.edu
                                </TextLinkA>{" "}
                                for details.
                            </li>
                            <li>Participate in the poster session at the end of the summer.</li>
                            <li>
                                Fill out a progress report in the middle and at the end of the
                                summer.
                            </li>
                            <li>
                                Not engage in other major activities that create a conflict of commitment with CURIS: CURIS is a full-time position. If you want to take a course or part-time position (e.g., section leading, summer RAship), email both your CURIS faculty advisor and Yesenia Gallegos, ygallego@stanford.edu, for approval. If you are taking courses during the summer while participating in CURIS, and if are on financial aid for that enrollment, your summer CURIS stipend may be reduced by the Financial Aid office—contact the Financial Aid office for details.
                            </li>
                        </ul>
                    </Paragraph>
                }
            />
            <CommonContentBlock
                header="How To Apply"
                description={
                    <Paragraph>
                        The Summer CURIS program uses a formal matching process,
                        where students create a profile and rank the projects they are interested in.
                        Faculty and graduate students rank applicants for each project. 
                        CURIS assigns students to projects using the &nbsp;
                        <a href="https://en.wikipedia.org/wiki/Gale-Shapley_algorithm">Gale-Shapley matching algorithm</a>, with student ranking of projects receiving priority over project ranking of students. To be considered for Summer 2025 CURIS,
                        you must follow these deadlines.
                        
                        <ul>
                            <li>
                                <b>January 6 - February 3: Project entry</b>
                                <ul>
                                    <li>
                                        Faculty members enter project descriptions on the CURIS site. The project listings must specify times for open office hours.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>February 3: Projects released to students</b>
                                <ul>
                                    <li>
                                        Students view and apply for projects on the CURIS site. This involves completing their profile (which includes a resume and unofficial transcript) and submitting a brief project-specific statement of interest. Students can rank their interest in projects on the site.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>February 4 - February 17: Project office hours</b>
                                <ul>
                                    <li>
                                        During this period, projects host office hours where students can learn more and ask questions.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>February 18: Student applications due</b>
                                <ul>
                                    <li>
                                        Student applications and project rankings are due by 2/18, 11:59pm PT.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>February 19 - March 3: Faculty review applications</b>
                                <ul>
                                    <li>
                                        During this period, faculty review student applications. Some projects may reach out to students to conduct interviews.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>March 3: Faculty rankings due</b>
                                <ul>
                                    <li>
                                        Faculty rankings for applicants are due by 3/3, 11:59pm PT.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>March 4: Project matching results released</b>
                                <ul>
                                    <li>
                                        Students and faculty members will be notified about project matching results. Students who do not receive an initial match may be placed on a waitlist.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>March 24: Student decisions due</b>
                                <ul>
                                    <li>
                                        Students must accept their internship offer by 3/24, 11:59pm PT.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Paragraph>
                }
                buttonText="See summer project listings"
                buttonUrl="/summer_projects/"
            />

            <CommonContentBlock
                header="Key Application Dates (Summer 2025)"
                dark
                description={
                    <Paragraph>
                        <Header3>FOR STUDENTS</Header3>
                        <ul>
                            <li><b>February 3:</b> Projects released</li>
                            <li><b>February 4 - 17:</b> Project office hours</li>
                            <li><b>February 18:</b> Project applications and rankings due 11:59pm PT</li>
                            <li><b>February 19 - March 3:</b> Project interviews</li>
                            <li><b>March 4:</b> Project matches released</li>
                        </ul>

                        <Header3>FOR FACULTY</Header3>
                        <ul>
                            <li><b>February 3:</b> Projects due</li>
                            <li><b>February 4 - 17:</b> Project office hours</li>
                            <li><b>February 19 - March 3:</b> Project interviews</li>
                            <li><b>March 3:</b> Applicant rankings due 11:59pm PT</li>
                            <li><b>March 4:</b> Project matches released</li>
                        </ul>
                    </Paragraph>
                }
            />
            <CommonContentBlock
                header="Summer 2025 Program Schedule"
                description={
                    <Paragraph>
                        The tentative schedule for Summer CURIS in 2025 is as follows:
                        <ul>
                            <li>
                                <b>June 23:</b> First day of Summer CURIS
                            </li>
                            <li>
                                <b>July 4:</b> University holiday.
                            </li>
                            <li>
                                <b>August 29:</b> Final poster session.
                            </li>
                            <li>
                                <b>August 29:</b> Last day of Summer CURIS. 
                            </li>
                        </ul>
                    </Paragraph>
                }
            />
            <CommonContentBlock
                header="Who’s Eligible"
                dark
                description={
                    <Paragraph>
                        To be eligible for CURIS, a student must maintain undergraduate status
                        through the summer. Students who are not Stanford undergraduates are not eligible for CURIS. 
			<br /><br />
                        You need to be selected by some faculty to join their project. It helps if
                        you've taken one or more relevant classes, and/or have some relevant
                        experience. If you don't think you have enough experience this year, it's
                        still worth trying, as different projects have different requirements.
                    </Paragraph>
                }
            />
            <CommonContentBlock
                header="CURIS Pay and Taxes"
                description={
                    <Paragraph>
                        Most summer CURIS students are paid via stipend (one payment through Axess
                        as Financial Aid) and will not receive a W-2 form from Stanford. You can
                        print out the record from Axess to use as backup for your tax return. If you
                        were paid twice a month throughout the summer, you were paid via the Payroll
                        system, and should receive a W-2 form. Both the stipend and payroll checks
                        are tax-reportable income. Students paid via stipend may want to consult a
                        tax advisor to see if they need to make estimated quarterly tax payments.
                    </Paragraph>
                }
            />
        </React.Fragment>
    );
};
