import { ReactComponent as CheckIcon } from "assets/check_24px.svg";
import { ReactComponent as XIcon } from "assets/close_24px.svg";
import { CommonContentBlock } from "components/ContentBlock";
import CurisButton from "components/CurisButton";
import { ErrorScreen } from "components/ErrorScreen";
import { Paragraph, TextLinkA, Header2, Header3 } from "components/Typography";
import React, { useState } from "react";
import { Button, Divider, Form, Icon, Loader, Modal, List } from "semantic-ui-react";
import SettingsResource from "resources/settings";
import UserResource from "resources/user";
import AssistantResource from "resources/assistant";
import StudentResource from "resources/student";
import { Field, Form as FinalForm } from "react-final-form";
import { useResource } from "rest-hooks";
import styled from "styled-components";
import { COLORS } from "components/STYLE_CONFIG";
import axios from "axios";
import { BASE_URL } from "resources/api";
import Cookies from "universal-cookie";
import { MainModal } from "../components/MainModal";
import { Toaster, toast } from "react-hot-toast";
import { Link } from "react-router-dom";

export const FacultyInformation: React.FC = () => {
  const [settingsList, user, assistants] = useResource(
    [SettingsResource.listShape(), {}],
    [UserResource.detailShape(), {}],
    [AssistantResource.listShape(), {}],
  );
  const settings = settingsList[0];

  const [toggle, setToggle] = useState(false);

  const required = (value: string | undefined) =>
    value ? undefined : "Required";

  const cookies = new Cookies();

  const onSubmit = ({ email }: { email: String }) => {
    axios
      .post(
        BASE_URL + "/api/authentication/permissions/",
        {
          email,
        },
        { headers: { Authorization: `Token ${cookies.get("authToken")}` } }
      )
      .then((resp) => {
        toast.success("Permissions successfully granted!");
      })
      .catch((err) => {
        let error_msg = err.response.data;
        toast.error(error_msg);
      });
  };

  if (!user.is_faculty_or_assistant)
    return (
      <ErrorScreen
        header="Page not available"
        message="The page you were trying to access is only available to faculty."
        showReturn
      />
    );

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <CommonContentBlock
        dark
        header="General information"
        description={
          <Paragraph>
            For questions regarding the administration of CURIS projects, please
            contact Yesenia Gallegos,{" "}
            <TextLinkA href="mailto:ygallego@stanford.edu">
              ygallego@stanford.edu
            </TextLinkA>
            . Refer any student questions about CURIS and CS department research
            to the CURIS program mentors,{" "}
            <TextLinkA href="mailto:curis-mentors@cs.stanford.edu">
              curis-mentors@cs.stanford.edu
            </TextLinkA>
            . If you are a new faculty member in CS looking to add projects to
            the CURIS site, please contact CSD-CF at{" "}
            <TextLinkA
              href="https://support.cs.stanford.edu"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.cs.stanford.edu
            </TextLinkA>{" "}
            to update your account type.
          </Paragraph>
        }
      />
      <CommonContentBlock
        header="Projects from departments other than CS"
        description={
          <Paragraph>
            CURIS encourages faculty from the broader university to list
            research projects that are looking for students with CS skills.
            These projects apply CS to research in any field.
          </Paragraph>
        }
        buttonText="Add a project outside of CS"
        buttonUrl="/projects_outside_cs/"
      />
      <CommonContentBlock
        dark
        header="CS projects during the academic year"
        description={
          <Paragraph>
            CURIS lists CS department projects involving looking for
            undergraduates and masters students during the academic year.
            Program participants work with faculty and graduate students for
            enrolled units or in paid positions. Whether a project has
            for-credit or for-pay positions is up to the faculty member
            sponsoring in. To apply, students reach out directly to the listed
            contact on the project.
          </Paragraph>
        }
        buttonText="Add an academic-year project"
        buttonUrl="/year_round_cs_projects/"
      />
      <CommonContentBlock
        header="Summer program (CURIS) overview"
        description={
          <Paragraph>
            The CURIS summer program is for Stanford undergraduates only.
            Students work with a faculty member in the CS department, joining
            their group for an entire summer working on a project of mutual
            interest. Students receive a one-time stipend or salary for the
            summer. The roadmap for prospective CURIS participants is as
            follows:
            <ul>
              <li>
                <b>Winter Quarter:</b> Students apply to and get matched to
                projects.
              </li>
              <li>
                <b>Spring Quarter:</b> Students prepare for their internships
                with their mentors. This may involve taking a class, reading
                papers, participating in group meetings, etc.
              </li>
              <li>
                <b>Summer Quarter:</b> Students perform research for 10 weeks
                (40 hours / week). Each student receives $7,500-$9,000 for this
                period, depending on student circumstances{" "}
                <TextLinkA
                  href="https://undergrad.stanford.edu/opportunities/research/information-faculty-staff/faculty-department-grant-administration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  as determined by VPUE's Dynamic Stipend Guidelines
                </TextLinkA>.
                If campus undergraduate housing is open, the student is also
                eligible to purchase on-campus room and board on campus. More
                information on summer housing can be found{" "}
                <TextLinkA
                  href="https://rde.stanford.edu/summer-housing-assignments"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </TextLinkA>
                .
              </li>
              <li>
                <b>End of summer:</b> Students present their work at a poster
                session with other CURIS participants.
              </li>
            </ul>
          </Paragraph>
        }
      />
      <CommonContentBlock
        dark
        header="Current status for summer programs"
        description={
          <Paragraph>
            <SettingsRow
              enabled={settings.faculty_add_assistants_enabled}
              description="Faculty can add assistants"
            />
            <SettingsRow
              enabled={settings.faculty_add_projects_enabled}
              description="Faculty can add projects to site"
            />
            <SettingsRow
              enabled={settings.faculty_rate_student_applications_enabled}
              description="Faculty can rate student applications"
            />
            <SettingsRow
              enabled={settings.student_view_projects_enabled}
              description="Students can apply to projects"
            />
            <SettingsRow
              enabled={settings.student_view_and_accept_project_matches}
              description="Students can accept matched projects"
            />
          </Paragraph>
        }
      />
      <CommonContentBlock
        header="Summer requirements"
        description={
          <Paragraph>
            The summer CURIS program requires students to:
            <ul>
              <li>
                Do research on campus for a full 10 weeks over summer. We
                strongly recommend that these ten weeks be the official ten week
                timeline below. However, if you need to be away for part of the
                official CURIS session, your faculty mentor and the CURIS staff
                can approve up to four weeks of vacation on the condition that
                you you begin your internship early or end your internship late
                to make up any missed days before the start of fall quarter.
                Please email Yesenia Gallegos,{" "}
                <TextLinkA href="mailto:ygallego@stanford.edu">
                  ygallego@stanford.edu
                </TextLinkA>{" "}
                for details.
              </li>
              <li>
                Participate in the poster session at the end of the summer.
              </li>
              <li>
                Fill out a progress report in the middle and at the end of the
                summer.
              </li>
              <li>
                Not take other jobs concurrently with the program. CURIS is a
                full-time employed position.
              </li>
            </ul>
          </Paragraph>
        }
      />
      <CommonContentBlock
        dark
        header="Summer CURIS Applications"
        description={
          <Paragraph>
            Applying to CURIS for the summer program requires a formal matching
            process, where students create a profile for faculty review, rank
            projects, and get assigned in a way that considers your preferences.
            To be considered, you must follow these deadlines:
            <ul>
              <li>
                  <b>January 6 - February 3: Project entry</b>
                  <ul>
                      <li>
                          Faculty members enter project descriptions on the CURIS site. The project listings must specify times for open office hours.
                      </li>
                  </ul>
              </li>
              <li>
                  <b>February 3: Projects released to students</b>
                  <ul>
                      <li>
                          Students view and apply for projects on the CURIS site. This involves completing their profile (which includes a resume and unofficial transcript) and submitting a brief project-specific statement of interest. Students can rank their interest in projects on the site.
                      </li>
                  </ul>
              </li>
              <li>
                  <b>February 4 - 17: Project office hours</b>
                  <ul>
                      <li>
                          During this period, projects host office hours where students can learn more and ask questions.
                      </li>
                  </ul>
              </li>
              <li>
                  <b>February 18: Student applications due</b>
                  <ul>
                      <li>
                          Student applications and project rankings are due by 2/18/25, 11:59pm PT.
                      </li>
                  </ul>
              </li>
              <li>
                  <b>February 19 - March 3: Faculty review applications</b>
                  <ul>
                      <li>
                          During this period, faculty review student applications. Some projects may reach out to students to conduct interviews.
                      </li>
                  </ul>
              </li>
              <li>
                  <b>March 3: Faculty rankings due</b>
                  <ul>
                      <li>
                          Faculty rankings for applicants are due by 3/3/25, 11:59pm PT.
                      </li>
                  </ul>
              </li>
              <li>
                  <b>March 4: Project matching results released</b>
                  <ul>
                      <li>
                          Students and faculty members will be notified about project matching results. Students who do not receive an initial match may be placed on a waitlist.
                      </li>
                  </ul>
              </li>
              <li>
                  <b>March 24: Student decisions due</b>
                  <ul>
                      <li>
                          Students must accept their internship offer by 3/24/25, 11:59pm PT.
                      </li>
                  </ul>
              </li>
          </ul>
          </Paragraph>
        }
        buttonText="Add a summer project"
        buttonUrl="/summer_projects/"
      />
      <CommonContentBlock
          header="Summer CURIS Application Timeline At-a-glance"
          description={
              <Paragraph>
                  <Header3>FOR FACULTY</Header3>
                  <ul>
                      <li><b>February 3:</b> Projects due</li>
                      <li><b>February 4 - 17:</b> Project office hours</li>
                      <li><b>February 19 - March 3:</b> Project interviews</li>
                      <li><b>March 3:</b> Applicant rankings due 11:59pm PT</li>
                      <li><b>March 4:</b> Project matches released</li>
                  </ul>

                  <Header3>FOR STUDENTS</Header3>
                  <ul>
                      <li><b>February 3:</b> Projects released</li>
                      <li><b>February 4 - 17:</b> Project office hours</li>
                      <li><b>February 18:</b> Project applications and rankings due 11:59pm PT</li>
                      <li><b>February 19 - March 3:</b> Project interviews</li>
                      <li><b>March 4:</b> Project matches released</li>
                  </ul>
              </Paragraph>
          }
      />
      {/* <CommonContentBlock
        header="Program schedule for summer 2022"
        description={
          <Paragraph>
            The schedule for summer CURIS in 2022 is as follows:
            <ul>
              <li>
                <b>Monday, June 24:</b> CURIS program starts. Kickoff Meeting
                10:00 AM.
              </li>
              <li>
                <b>Friday, July 4:</b> University holiday.
              </li>
              <li>
                <b>Wednesday, August 28:</b> Poster and slide due.
              </li>

              <li>
                <b>Thursday, August 29:</b> Afternoon poster session.
                Instructions will be sent by email. .
              </li>
              <li>
                <b>Friday, August 30:</b> Last day of summer CURIS.
              </li>
              <li>
                <b>Friday, September 30:</b> Fall poster session (re-present
                projects in person to full campus, campus status willing).
              </li>
            </ul>
          </Paragraph>
        }
      /> */}
      <CommonContentBlock
        dark
        header="Summer program eligibility"
        description={
          <Paragraph>
            To be eligible for CURIS, a student must maintain undergraduate status through the summer. 
	    Students who are not Stanford undergraduates are not eligible for CURIS.
          </Paragraph>
        }
      />

      <CommonContentBlock
        header="Grant faculty-assistant permission"
        description={
          <>
            <div style={{ marginBottom: "30px" }}>
              <Paragraph>Grant faculty-assistant permission to PhD students to allow them to post projects and review applicants</Paragraph>
            </div>
            <CurisButton onClick={() => setToggle(true)}>
              Grant permission
            </CurisButton>
          </>
        }
      />

      <MainModal
        toggle={toggle}
        onClose={() => setToggle(false)}
        headerText="Grant faculty-assistant permission"
      >
        To be granted faculty-assistant status, the PhD student needs to first have an account on the CURIS site. They can do this by visiting the <Link to="/profile/" target="_blank">My Profile page</Link> and following the Stanford login steps, which will automatically set up an account on the site. 
        {/* <div style={{ marginTop: "30px", marginBottom: "30px"}}>
          <Header3 color={COLORS.BLACK}>Your current faculty-assistants</Header3>
          <List as="ol">
            {assistants.map((assistant, index) =>
              (assistant.faculty_user_id === user.id) ?
              (
                <AssistantInfo asst_id={assistant.assistant_user_id} index={index} />
              ) : null
            )}
          </List>
        </div> */}
        <div style={{ marginTop: "30px"}}>
        <Form>
          <FinalForm
            onSubmit={onSubmit}
            initialValues={{}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <Field name="email" validate={required}>
                  {({ input, meta }) => (
                    <div style={{ marginBottom: "18px" }}>
                      <StyledInput
                        name={input.email}
                        label="Enter the SUNet of a faculty-assistant to add (ex: jdoe1):"
                        value={input.value}
                        onChange={input.onChange}
                        error={meta.error && meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>
                <CurisButton type="submit">Grant faculty permission</CurisButton>
              </form>
            )}
          />
        </Form>
        </div>
      </MainModal>
    </>
  );
};

const StyledInput = styled(Form.Input)`
  font-family: Lato !important;
  font-size: 18px !important;
  line-height: 29px !important;
  font-weight: 600 !important;
  color: ${COLORS.DARK_GREY} !important;

  & label {
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 29px !important;
    font-weight: 600 !important;
  }

  & input {
    padding: 13px !important;
  }
`;

const SettingsRow: React.FC<{ enabled: boolean; description: string }> = ({
  enabled,
  description,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {enabled ? (
        <CheckIcon style={{ marginRight: "20px" }} />
      ) : (
        <XIcon style={{ marginRight: "20px" }} />
      )}
      <Paragraph>{description}</Paragraph>
    </div>
  );
};

const AssistantInfo: React.FC<{asst_id: string, index: number}> = ({
  asst_id,
  index,
}) => {
  const asst = useResource(StudentResource.listShape(), {
    user_id: asst_id,
  })![0];
  if (asst) {
    return (
      <List.Item
        key={`key-${index}`}
        as="li"
      >
        Assistant name: {asst.name}, email: {asst.sunet}@stanford.edu
      </List.Item>
    )
  }
  return null;
  
  
}
