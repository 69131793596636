import { COLORS } from "components/STYLE_CONFIG";
import { default as React, useState } from "react";
import { Header1, Header2, Header3, Paragraph } from "components/Typography";
import { Link } from "react-router-dom";
import CurisButton from "components/CurisButton";
import styled from "styled-components";

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const Fellowship: React.FC = () => {
    return (
        <PageWrapper>
            <Header1 color={COLORS.DARK_RED}>CURIS Fellowships</Header1>
            <div style={{ marginTop: "30px", marginBottom: "40px"}}>
                <Paragraph>
                    <b><a href="https://curis.stanford.edu/">CURIS</a></b> is the undergraduate research program of Stanford's Computer Science Department. Each summer, 100+ undergraduates conduct and participate in computer science research advised and mentored by faculty and Ph.D. students.<br/><br/>

                    CURIS Fellowships provide guaranteed funding for undergraduates to participate in summer research. A fellowship student's stipend is paid for by the department. A student awarded a CURIS fellowship then enters the matching process for the CURIS project listings with a special designation that they have received the CURIS Fellowship and are already funded, making them more attractive to the projects. We expect to grant 15 fellowships for the summer of 2025.<br/><br/>    

                    CURIS Fellowships are intended for students who are just starting in research. The goal for the Fellowship program is to give a diverse set of less advanced or experienced students an opportunity to participate in research earlier in their academic career. All Stanford undergraduates are eligible for a CURIS Fellowship, but you must have taken CS 106B by the time you apply. You also must not have prior experience working on research in a Stanford CS Lab. Finally, to be eligible, you must retain an active undergraduate status during the summer (cannot be on suspension, cannot be taking a gap year, cannot be on a leave of absence, cannot be paying graduate tuition). Students accepted to the co-term automatically switch to graduate tuition status after 12 quarters, regardless of enrollment, so if you are graduating in June, or will have "flipped" to MS as a co-term automatically after your 12th quarter, you cannot participate. Please double check that you meet these criteria. The recipients of Fellowships in 2024-2025 will be selected with an emphasis on students who:
                    <ul>
                        <li>do not have prior CS research experience,</li>
                        <li>show promise as researchers in their interests and curiosity, and</li>
                        <li>will help make research accessible to a diverse group of students.</li>
                    </ul>

                    Regardless of whether they receive a CURIS Fellowship, all Stanford students are welcome and invited to apply to CURIS positions when they open for application.<br/><br/>

                    CURIS stipends are set by the University. This summer, all students receive a $8,000 stipend, and students may apply for additional need-based support of $1,500. Awarding of the need-based aid is managed by the Vice Provost for Undergraduate Education (VPUE) in coordination with the Financial Aid office. 
                </Paragraph>
            </div>

			<Header2>Fellowship Application Process</Header2><br/>
			<Paragraph>
                Your application consists of three parts: your resume, transcript, and a brief essay on a research paper. A subset of applicants will be contacted for an interview in January.<br/><br/>

                Please apply via <a href="https://forms.gle/F4RfMYkVdt9iaGn68">this Google Form</a>. To apply, you will need:
                <ul>
                    <li>A PDF of your resume</li>
                    <li>A PDF of your unofficial Stanford transcript including your fall grades</li>
                    <li>A PDF containing both your research essay
                        <ul>
                            <li>Research Essay: 500-word essay on a research reading (listed below)</li>
                        </ul>
                    </li>
                </ul>

                <Link to={{ pathname: "https://forms.gle/F4RfMYkVdt9iaGn68" }} target="_blank">
                    <CurisButton>Apply here</CurisButton>
                </Link><br/>
            </Paragraph><br/>

            <Header3>TIMELINE</Header3><br/>
			<Paragraph>
		Applications are due on January 8, 2025 so applicants have time to write a thoughtful research statement after the quarter ends. Applications are welcomed from students from all backgrounds. Applicants who do not receive a Fellowship may still apply to CURIS through the regular process. Awards will be made with awareness of and in conformance with the Supreme Court’s June 2023 ruling on affirmative action. Students who are awarded a fellowship may use the CURIS website to find a project to work on.
                <ul>
                    <li>January 8, 2025: Applications are due (11:59pm PT)</li>
                    <li>February 3, 2025: Fellowship results announced</li>
                </ul>
            </Paragraph><br/>

			<Header3>RESEARCH ESSAY</Header3><br/>
			<Paragraph>
		Your application must include an approximately 500-word research essay on one of following papers. We don’t expect an applicant to be able to provide PhD-level commentary on the work. We do ask you to engage with the core ideas introduced by the work. What ideas does this paper open up to you? If you could add one experiment to the paper, what would it be? What’s an interesting way this result could be used that the author’s don’t talk about? Don’t spend more than a paragraph summarizing the paper and don’t waste space nitpicking it: we are looking for evidence of critical thinking and engagement with the material.<br/><br/>

                Please pick from one of these Research Highlight articles.
                <ul>
                    <li>Systems
                        <ul>
                            <li>
                                <a href="https://cacm.acm.org/research/the-tail-at-scale">The Tail at Scale</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3399742">Spectre Attacks: Exploiting Speculative Execution</a>
                            </li>
                            <li>
                                <a href="https://cacm.acm.org/research/technical-perspective-tracking-pandemic-driven-internet-traffic">Technical Perspective: Tracking Pandemic-Driven Internet Traffic</a>
                            </li>
                        </ul>
                    </li>

                    <li>Theory
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/abs/10.1145/3339185">Heavy Hitters via Cluster-Preserving Clustering</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3394625">Data-Driven Algorithm Design</a>
                            </li>
                            <li>
                                <a href="https://arxiv.org/pdf/1609.05807">Inherent Trade-Offs in the Fair Determination of Risk Scores</a>
                            </li>
                        </ul>
                    </li>

                    <li>AI
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/pdf/10.1145/3431283">Unveiling Unexpected Training Data in Internet Video</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3505267">Supporting People with Autism Spectrum Disorders in the Exploration of PoIs: An Inclusive Recommender System</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3470442">Optimal Auctions Through Deep Learning</a>
                            </li>
                        </ul>
                    </li>

                    <li>NLP
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/pdf/10.1145/3593013.3594095">Easily Accessible Text-to-Image Generation Amplifies Demographic Stereotypes at Large Scale</a>
                            </li>
                            <li>
                                <a href="https://arxiv.org/pdf/1306.6078.pdf">A Computational Approach to Politeness with Application to Social Factors</a>
                            </li>
                        </ul>
                    </li>

                    <li>HCI
                        <ul>
                            <li>
                                <a href="https://arxiv.org/pdf/2304.03442.pdf">Generative Agents: Interactive Simulacra of Human Behavior</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/1978542.1978564">Skinput: Appropriating the Skin as an Interactive Canvas</a>
                            </li>
                        </ul>
                    </li>

                    <li>Graphics
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/abs/10.1145/3344808">OpenFab: A Programmable Pipeline for Multimaterial Fabrication</a>
                            </li>
                        </ul>
                    </li>

                    <li>Biocomp
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3441686">BioScript: Programming Safe Chemistry on Laboratories-on-a-Chip</a>
                            </li>
                        </ul>
                    </li>
                </ul>

            </Paragraph><br/>

			
        </PageWrapper>
        )}
